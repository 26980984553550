import React, { useCallback, useRef, useState } from 'react'

import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'

import Close from '@mui/icons-material/Close'
import debounce from 'helpers/node/debounce'

export default function AddableList({ options, setOptions, readOnly }) {
  const anchorRef = useRef()
  const inputRef = useRef()
  const focusRef = useRef({ focused: false })
  const [open, setOpen] = useState(false)
  const [textValue, setTextValue] = useState('')

  const handleClose = useCallback(
    debounce(() => {
      if (!focusRef?.current?.focused) {
        setOpen(false)
      }
    }, 1),
    []
  )

  return (
    <Box>
      <TextField
        size="small"
        ref={anchorRef}
        inputRef={inputRef}
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setOptions([...new Set(options.concat(textValue))])
            setTextValue('')
          }
        }}
        onFocus={() => {
          if (readOnly) return

          focusRef.current.focused = true
          setOpen(true)
        }}
        onBlur={() => {
          focusRef.current.focused = false
        }}
        placeholder={options.join(', ')}
        inputProps={{
          readOnly,
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus
        PaperProps={{
          sx: {
            boxShadow: 3,
          },
        }}
      >
        <List
          sx={{
            width: anchorRef.current?.clientWidth,
            maxHeight: '400px',
          }}
          disablePadding
        >
          {!options.length && (
            <ListItem>
              <ListItemText sx={{ color: 'darkgray.light' }}>
                Add items with enter
              </ListItemText>
            </ListItem>
          )}
          {options.map((option, index) => (
            <ListItem
              divider
              key={option}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="close"
                  onClick={() => {
                    const newOptions = options.slice()
                    newOptions.splice(index, 1)
                    setOptions(newOptions.slice(0, 1000))
                  }}
                >
                  <Close />
                </IconButton>
              }
            >
              <ListItemText
                primary={option}
                onClick={() => inputRef.current.focus()}
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </Box>
  )
}
