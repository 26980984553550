import React, { useEffect, useMemo } from 'react'
import { useFormikContext } from 'formik'
import format from 'date-fns/format'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'

import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'

import Close from '@mui/icons-material/Close'

import DateRangePicker from 'components/common/DateRangePicker'
import AddableList from 'components/common/AddableList'
import {
  FILTER_OPTION_BY_TYPE,
  FILTER_OPTION_DISPLAYS,
  COLUMN_TYPES,
  getVarcharMaxLength,
  getColumnType,
} from 'utils/report'

function FilterActionSelect({ value, column, columnType, readonly }) {
  const { setFieldValue } = useFormikContext()

  const options = useMemo(() => {
    return FILTER_OPTION_BY_TYPE[columnType] || []
  }, [columnType])

  if (readonly) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {FILTER_OPTION_DISPLAYS[value.action] || value.action}
      </Box>
    )
  }

  return (
    <Select
      readOnly={readonly}
      fullWidth
      value={value.action}
      onChange={(e) => {
        setFieldValue(`report_filters.${value.index}.action`, e.target.value)
      }}
      size="small"
      sx={{ textAlign: 'center' }}
    >
      <MenuItem value="eq">{'='}</MenuItem>
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {FILTER_OPTION_DISPLAYS[option] || option}
        </MenuItem>
      ))}
    </Select>
  )
}

function FilterTargetSelect({ value, column, columnType, action, readonly }) {
  const { setFieldValue, values } = useFormikContext()

  const filterValue = values.report_filters[value.index].target
  const isUneditable = readonly && value.userDefinable === false

  const handleValueChange = (newValue) => {
    setFieldValue(`report_filters.${value.index}.target`, newValue)
  }

  useEffect(() => {
    if (
      columnType === COLUMN_TYPES.DATETIME &&
      Array.isArray(filterValue) &&
      filterValue.length &&
      action !== 'between'
    ) {
      handleValueChange([])
    }

    if (
      columnType === COLUMN_TYPES.DATETIME &&
      typeof filterValue === 'string'
    ) {
      const newDate = new Date(filterValue)
      if (newDate.toString() === 'Invalid Date') {
        handleValueChange(new Date())
      } else {
        handleValueChange(new Date(filterValue))
      }
    }
  }, [columnType, filterValue, action])

  if (columnType === COLUMN_TYPES.BOOLEAN) {
    if (isUneditable) {
      return (
        <Box
          sx={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {filterValue ? 'TRUE' : 'FALSE'}
        </Box>
      )
    }

    return (
      <Select
        fullWidth
        size="small"
        variant="outlined"
        value={filterValue}
        readOnly={isUneditable}
        onChange={(e) => {
          handleValueChange(e.target.value)
        }}
      >
        <MenuItem value={true}>TRUE</MenuItem>
        <MenuItem value={false}>FALSE</MenuItem>
      </Select>
    )
  }

  if (columnType === COLUMN_TYPES.DATETIME) {
    const dateRangePickerProps = {}

    if (isUneditable) {
      dateRangePickerProps.onClick = (e) => {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    if (isUneditable) {
      let displayValue = 'N/A'

      const dates = Array.isArray(filterValue) ? filterValue : [filterValue]

      try {
        displayValue = dates
          .map((date) => format(date, 'M/dd/yyyy'))
          .join(' and ')
      } catch (err) {
        displayValue = 'N/A'
      }

      return (
        <Box
          sx={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {displayValue}
        </Box>
      )
    }

    try {
      return (
        <Box>
          <DateRangePicker
            single={action !== 'between'}
            value={Array.isArray(filterValue) ? filterValue : [filterValue]}
            setValue={(val) => {
              if (action === 'between') {
                handleValueChange(val)
              } else {
                handleValueChange(val?.[0])
              }
            }}
            size="small"
            dateFormat={{
              dateFormat: 'MM/dd/yyyy',
            }}
            {...dateRangePickerProps}
          />
        </Box>
      )
    } catch (err) {
      return <></>
    }
  }

  if (action === 'in') {
    const options =
      (Array.isArray(values.report_filters[value.index].target)
        ? values.report_filters[value.index].target
        : values.report_filters[value.index].target?.split(',')
      )?.filter((v) => v) || []

    if (isUneditable) {
      return (
        <Box
          sx={{
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {options.join(', ')}
        </Box>
      )
    }

    return (
      <AddableList
        options={options}
        setOptions={(options) => {
          setFieldValue(
            `report_filters.${value.index}.target`,
            options.join(',')
          )
        }}
        readOnly={isUneditable}
      />
    )
  }

  if (isUneditable) {
    return (
      <Box
        sx={{
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {filterValue?.toString()}
      </Box>
    )
  }

  return (
    <TextField
      size="small"
      variant="outlined"
      value={filterValue}
      type={columnType === COLUMN_TYPES.NUMBER ? 'number' : 'text'}
      inputProps={{
        maxLength: getVarcharMaxLength(column),
        readOnly: isUneditable,
      }}
      onChange={(e) => {
        handleValueChange(e.target.value)
      }}
    />
  )
}

export default function ValueDisplay({ value, type, onClose, readonly }) {
  const { setFieldValue, values, status } = useFormikContext()

  const getValueDisplay = () => {
    if (type === 'sort') {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Typography my="auto" fontWeight="600">
            {`${value}`}
          </Typography>
          <Select
            value={values.report_sorts[value].dir}
            onChange={(e) => {
              setFieldValue(`report_sorts.${value}.dir`, e.target.value)
            }}
            size="small"
          >
            <MenuItem value="asc">Ascending</MenuItem>
            <MenuItem value="desc">Descending</MenuItem>
          </Select>
        </Box>
      )
    }

    if (type === 'filter') {
      const column = status.columns.find((col) => col.col === value.col)
      const columnType = getColumnType(column)

      return (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '200px 1fr 2fr',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'inline-block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '200px',
              fontWeight: 600,
              py: 2,
              textAlign: 'center',
              cursor: 'pointer',
            }}
            title={`${value.col} (${columnType})`}
          >
            {`${value.col}`}
          </Box>
          <Box>
            <Box sx={{ px: 1, height: '100%' }}>
              <FilterActionSelect
                value={value}
                column={column}
                columnType={columnType}
                readonly={readonly}
              />
            </Box>
          </Box>
          <Box>
            <FilterTargetSelect
              value={value}
              column={column}
              columnType={columnType}
              action={values.report_filters[value.index].action}
              readonly={readonly}
            />
          </Box>
        </Box>
      )
    }

    return (
      <Typography my="auto" fontWeight="600">
        {`${value}`}
      </Typography>
    )
  }

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 2,
        boxShadow: 3,
        width: '100%',
        mb: 3,
      }}
    >
      {getValueDisplay()}
      {!readonly && (
        <>
          {type === 'filter' && (
            <Tooltip
              title={
                value.userDefinable !== false ? 'User definable' : 'Read-only'
              }
            >
              <IconButton
                size="small"
                color={value.userDefinable === false ? 'primary' : undefined}
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'primary.main',
                  },
                }}
                onClick={() => {
                  setFieldValue(
                    `report_filters.${value.index}.userDefinable`,
                    !value.userDefinable
                  )
                }}
              >
                {value.userDefinable !== false ? (
                  <LockOpenOutlinedIcon />
                ) : (
                  <LockOutlinedIcon />
                )}
              </IconButton>
            </Tooltip>
          )}
          <IconButton
            size="small"
            onClick={onClose}
            sx={{
              p: 0,
              '&:hover': {
                backgroundColor: 'transparent',
                color: 'error.main',
              },
            }}
          >
            <Close />
          </IconButton>
        </>
      )}
    </Card>
  )
}
