import useSWR from 'swr'

import fetcher, { outgoingFetcher } from 'utils/fetcher'

const useInstanceConfiguration = ({ instanceName, internalOnly = false }) => {
  let endpoint = 'instance-configurations'

  if (internalOnly) {
    endpoint = 'internal-instance-configurations'
  }

  const { data } = useSWR(
    instanceName
      ? `${process.env.REACT_APP_BASE_URL}/${instanceName}/${endpoint}`
      : null,
    internalOnly ? fetcher : outgoingFetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const instanceConfigurations = {}

  if (
    data?._embedded?.instance_configurations &&
    typeof data._embedded.instance_configurations === 'object'
  ) {
    const keys = Object.keys(data._embedded.instance_configurations)
    keys.forEach((configKey) => {
      instanceConfigurations[configKey] =
        data._embedded.instance_configurations[configKey].value
    })
  }

  return {
    instanceConfigurations: instanceConfigurations,
    isFetched:
      Boolean(data?._embedded?.instance_configurations) ||
      !instanceName ||
      data?.error,
  }
}

export default useInstanceConfiguration
