import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import CurrentBalance from 'components/postage/CurrentBalance'
import EditNotifications from 'components/postage/EditNotifications'
import PostageInvoices from 'components/postage/PostageInvoices'
import TransactionActivity from 'components/postage/TransactionActivity'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ClientSelect from 'components/postage/ClientSelect'

function PostagePage() {
  const [mounted, setMounted] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [clientId, setClientId] = useState(searchParams.get('clientId'))
  const [showClientId, setShowClientId] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return <></>

  return (
    <Box sx={{ width: '100%', overflow: 'hidden' }}>
      <Box
        sx={{
          height: '72px',
          background: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          px: 6,
          borderBottom: '1px solid',
          borderColor: 'lightgray.main',
        }}
      >
        <Typography variant="h1" sx={{ mt: 7 }}>
          Postage
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <EditNotifications />
        </Box>
      </Box>
      <Box sx={{ py: 8, px: 8 }}>
        <Box
          sx={{
            display: 'flex',
            height: { xs: '100%' },
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box sx={{ mr: { md: 6 }, mb: { xs: 12, lg: 0 } }}>
            <ClientSelect
              value={clientId}
              setValue={(value) => {
                setClientId(value)

                if (showClientId) {
                  if (!value) {
                    setSearchParams({}, { replace: true })
                  } else {
                    setSearchParams({ clientId: value }, { replace: true })
                  }
                }
              }}
              setHasMultiple={setShowClientId}
            />
            <CurrentBalance clientId={clientId} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <PostageInvoices clientId={clientId} showClientId={showClientId} />
          </Box>
        </Box>
        <Box sx={{ mt: 12 }}>
          <TransactionActivity clientId={clientId} />
        </Box>
      </Box>
    </Box>
  )
}

export default PostagePage
