import React from 'react'

import Box from '@mui/material/Box'
import ToggleButton from '@mui/material/ToggleButton'
import Typography from '@mui/material/Typography'

import TuneIcon from '@mui/icons-material/Tune'

function FilterToggleButton({
  open,
  toggleOpen,
  filterCount,
  toggleButtonProps,
}) {
  return (
    <>
      <Box sx={{ zIndex: 1, mr: 3 }}>
        {filterCount > 0 && (
          <Typography color="primary" fontWeight="medium">
            Filters Applied ({filterCount})
          </Typography>
        )}
      </Box>
      <ToggleButton
        color="primary"
        value="list"
        sx={{ mr: 4 }}
        selected={!open}
        onClick={toggleOpen}
        {...toggleButtonProps}
      >
        <TuneIcon />
      </ToggleButton>
    </>
  )
}

export default FilterToggleButton
