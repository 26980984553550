import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useFormikContext } from 'formik'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import ScrollableBox from 'components/styled/ScrollableBox'
import NotesItem from 'components/notes/NotesItem'
import ResponsiveDrawer from 'components/common/Drawer/Responsive'
import FormRenderer from 'components/common/FormRenderer'

import useConfiguration from 'hooks/useConfiguration'
import { ORDER_SEARCHABLE_METADATA } from 'utils/constants'

function AddNoteSection({ sx }) {
  const [row, setRows] = useState(1)
  const { values, handleChange, handleSubmit, isSubmitting, dirty } =
    useFormikContext()

  return (
    <Box sx={{ ...sx, height: 'auto', pb: 12 }}>
      <Typography variant="h2" fontWeight="medium" pb={4}>
        Add note
      </Typography>
      <TextField
        multiline
        fullWidth
        rows={row}
        onBlur={() => setRows(1)}
        onFocus={() => setRows(8)}
        variant="filled"
        value={values.note}
        onChange={(e) =>
          handleChange({ target: { name: 'note', value: e.target.value } })
        }
      />
      <Button
        color="primary"
        variant="contained"
        size="action-header"
        sx={{ mt: 4, mb: 6, float: 'right' }}
        onClick={() => handleSubmit()}
        disabled={isSubmitting || !dirty}
      >
        Submit
      </Button>
    </Box>
  )
}

function CommentContent({
  notes = [],
  label = 'Order ID',
  id,
  orderId,
  open,
  loading,
  height,
}) {
  const scrollRef = useRef(null)

  const sharedStyles = {
    px: 6,
    py: 6,
  }

  useEffect(() => {
    if (scrollRef.current && open) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [scrollRef.current, open])

  return (
    <Box
      sx={{
        height,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '72px 1fr auto',
        width: '350px',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          borderBottom: '1px solid',
          borderBottomColor: 'lightgray.main',
          px: sharedStyles.px,
          pb: 4,
        }}
      >
        <Typography variant="h1" pb={1}>
          Notes
        </Typography>
        <Typography fontSize={14} color="darkgray.main">
          {label} {orderId || id || 'N/A'}
        </Typography>
      </Box>
      <ScrollableBox
        ref={scrollRef}
        sx={{
          ...sharedStyles,
          height: 'auto',
          overflowY: 'auto',
          display: 'flex',
          wordBreak: 'break-word',
        }}
      >
        {notes.length === 0 && loading ? (
          <CircularProgress thickness={2} sx={{ mx: 'auto' }} />
        ) : (
          <Stack spacing={8}>
            {notes.map((note, noteIndex) => (
              <NotesItem key={`note-${noteIndex}`} note={note} />
            ))}
          </Stack>
        )}
      </ScrollableBox>
      <AddNoteSection sx={{ ...sharedStyles }} />
    </Box>
  )
}

function FilterDocumentContent({ height, handleSubmit, formikRef }) {
  const { fieldDefinitions, instanceConfigurations, getMetadataDisplay } =
    useConfiguration()

  const footerActionProps = [
    {
      children: 'Search',
      color: 'primary',
      variant: 'contained',
      type: 'submit',
    },
    {
      children: 'Clear',
      color: 'primary',
      variant: 'outlined',
      bgcolor: 'white',
      onClick: () => {
        formikRef.current.resetForm({ values: {} })
        formikRef.current.submitForm()
      },
    },
  ]

  const renderingData = useMemo(() => {
    const baseFields = [
      {
        field: 'id',
        display: 'DOCUMENT ID',
      },
      {
        field: 'account_id',
        display: 'ACCOUNT OR CUSTOMER #',
      },
      {
        field: 'alternate_id',
        display: 'ALTERNATE ID',
      },
    ]
    const fields = []
    const searchableMetadata =
      instanceConfigurations?.[ORDER_SEARCHABLE_METADATA] || []

    searchableMetadata.forEach((col) => {
      const metadataKey = `_.metadata.${col}`
      const field = fieldDefinitions?.[metadataKey]

      if (field) {
        field.display = getMetadataDisplay(metadataKey)
        fields.push(field)
      }
    })

    return baseFields.concat(fields)
  }, [instanceConfigurations, fieldDefinitions])

  return (
    <Box
      sx={{
        height,
        width: '350px',
        overflow: 'hidden',
        paddingX: 4,
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <Typography fontWeight={'bold'} variant="h2">
          Search / Filter
        </Typography>
      </Box>
      <FormRenderer
        renderingData={renderingData}
        footerActionProps={footerActionProps}
        handleSubmit={handleSubmit}
        isCorrespondence={true}
        innerRef={formikRef}
        scrollHeight="60vh"
      />
    </Box>
  )
}

function OrderDetailDrawer({
  sx,
  open,
  setOpen,
  height = 'calc(100vh - 72px - 64px)',
  variant = 'comment',
  ...props
}) {
  let Content = CommentContent

  if (variant === 'filter-document') {
    Content = FilterDocumentContent
  }

  return (
    <ResponsiveDrawer
      open={open}
      setOpen={setOpen}
      width="350px"
      sx={{ position: 'fixed' }}
    >
      <Content height={height} open={open} {...props} />
    </ResponsiveDrawer>
  )
}

export default OrderDetailDrawer
