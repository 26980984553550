import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import useInstance from 'hooks/instance/useInstance'

function AssetTableCol({ column, sortList, handleColumnClick }) {
  if (!column) return <TableCell />

  if (column.sortable) {
    return (
      <TableCell>
        <TableSortLabel
          active={
            sortList && sortList['sort[0]']?.match(new RegExp(`^${column.id}:`))
          }
          direction={sortList?.['sort[0]']?.split(':')?.[1]}
          onClick={() => handleColumnClick(column.id)}
        >
          {column.display}
        </TableSortLabel>
      </TableCell>
    )
  }

  return <TableCell>{column.display}</TableCell>
}

function AssetsTable({
  dataList,
  columns,
  getDataDisplay,
  sortList,
  setSortList,
  loading,
}) {
  const { getPrintAssetVisibleColumns } = useInstance()
  const visibleColumns = useMemo(getPrintAssetVisibleColumns, [
    getPrintAssetVisibleColumns,
  ])

  const handleColumnClick = useCallback(
    (col) => {
      // alternate between not sorted, asc, and desc
      if (!sortList['sort[0]']) {
        setSortList({ 'sort[0]': `${col}:asc` })
      } else if (sortList['sort[0]'] === `${col}:asc`) {
        setSortList({ 'sort[0]': `${col}:desc` })
      } else {
        setSortList({})
      }
    },
    [sortList, setSortList]
  )

  const getRowDisplay = useCallback(
    (row, col) => {
      if (col.match(/^TAG_/) && col.length > 4) {
        const versions = row?._embedded?.versions?.versions || []
        if (!versions) {
          return 'N/A'
        }

        const defaultVersion = row._embedded.versions?.default || false
        if (!defaultVersion) {
          return 'N/A'
        }

        const displayedVersion = versions.find((v) => Number(v.version) === Number(defaultVersion))

        if (!displayedVersion) {
          return 'N/A'
        }

        return displayedVersion?.custom_data?.[col.slice(4)] || 'N/A'
      }

      return getDataDisplay(row, col)
    },
    [getDataDisplay]
  )

  return (
    <TableContainer component={Paper}>
      {loading ? (
        <Box
          sx={{
            height: '64px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table sx={{ minWidth: 650 }} aria-label="assets-table">
          <TableHead>
            <TableRow>
              {visibleColumns.map((col) => (
                <AssetTableCol
                  key={col}
                  column={columns.find((c) => c.id === col)}
                  handleColumnClick={handleColumnClick}
                  sortList={sortList}
                />
              ))}
              <TableCell>{/* Details */}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {visibleColumns.map((col) => (
                  <TableCell key={col}>{getRowDisplay(row, col)}</TableCell>
                ))}
                <TableCell>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      sx={{ mr: 2 }}
                      LinkComponent={Link}
                      to={`/correspondence-hub/print-asset/${row.key_id}`}
                    >
                      Details
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  )
}

export default AssetsTable
