import React, { useState } from 'react'

import Box from '@mui/material/Box'

import Typography from '@mui/material/Typography'

import Modal from 'components/common/Modal'
import TextField from '@mui/material/TextField'

import useOrderUpdate from 'hooks/useOrderUpdate'

import useNotification from 'hooks/context/useNotification'

import { useOrderNotes } from 'hooks/useOrders'

import rh from 'helpers/css/rh'

function OrderRejectModal(props) {
  const [note, setNote] = useState('')

  const { updateOrderStatus } = useOrderUpdate(props.orderId)

  const { setBasicNotification, setError } = useNotification()

  const { makeNote } = useOrderNotes(props.orderId)

  const overrideProps = {
    title: `Reject Order #${props.orderId}`,
    subTitle: undefined,
    scrollable: false,
    size: undefined,
    hideButtonsBorder: true,
    width: '1144px',
    height: rh(432),
    footerButtonProps: [
      {
        children: 'Submit',
        color: 'primary',
        variant: 'contained',
        onClick: async () => {
          try {
            await updateOrderStatus({
              status: 'Cancelled',
              status_description: `Notes: ${note}`,
            })
            await makeNote(note)
            props.mutate()
            setBasicNotification(`Order #${props.orderId} was rejected.`)
          } catch (err) {
            setError(err.response?.data?.display_message || 'Unauthorized')
          } finally {
            props.onClose()
          }
        },
        size: 'action-header',
      },
      {
        children: 'Cancel',
        color: 'primary',
        variant: 'outlined',
        onClick: props.onClose,
        size: 'action-header',
      },
    ],
  }

  return (
    <Modal {...props} {...overrideProps}>
      <Box>
        <Box sx={{ mb: 10 }}>
          <Typography variant="h2" fontWeight="medium" sx={{ mb: 1 }}>
            Comments:
          </Typography>

          <TextField
            fullWidth
            multiline
            placeholder="Please type a reason for rejecting this order"
            rows={7}
            sx={{ height: '100%' }}
            variant="filled"
            onBlur={(e) => setNote(e.target.value)}
          />
        </Box>
      </Box>
    </Modal>
  )
}

export default OrderRejectModal
