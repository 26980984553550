import React, { useCallback, useMemo, useState } from 'react'
import { Formik, useFormikContext } from 'formik'
import get from 'lodash.get'
import deepEqual from 'deep-equal'

import Fab from '@mui/material/Fab'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'

import BellIcon from '@mui/icons-material/Notifications'
import SaveIcon from '@mui/icons-material/Save'
import XIcon from '@mui/icons-material/Close'

import usePostageBalance from 'hooks/postage/usePostageBalance'
import useNotification from 'hooks/context/useNotification'

import useAuth from 'hooks/useAuth'
import useUser from 'hooks/users/useUser'
import usePostageAlerts from 'hooks/postage/usePostageAlerts'

const NOTIFICATION_OPTIONS = ['sms', 'email']

function PostageOption({ client }) {
  const { values, handleChange } = useFormikContext()
  const { user } = useAuth()
  const { user: authUser } = useUser(user?.userId)

  return (
    <>
      <Divider sx={{ my: 1 }} />
      <Stack>
        <Typography fontWeight="bold">{client.client_name}</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', '& > *': { mr: 2 } }}>
          {NOTIFICATION_OPTIONS.filter(
            (opt) => opt !== 'sms' || (opt === 'sms' && authUser?.phone)
          ).map((opt) => (
            <Box sx={{ display: 'flex' }}>
              <Typography sx={{ textTransform: 'uppercase', my: 'auto' }}>
                {opt}:
              </Typography>
              <Checkbox
                id={`clients.${client.client_id}.${opt}`}
                name={`clients.${client.client_id}.${opt}`}
                size="small"
                sx={{ my: 'auto' }}
                color="green"
                checked={
                  get(values.clients, `${client.client_id}.${opt}`) === 1
                }
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: e.target.name,
                      value: e.target.checked ? 1 : 0,
                    },
                  })
                }}
              />
            </Box>
          ))}
        </Box>
      </Stack>
    </>
  )
}

function EditNotifications() {
  const [anchorEl, setAnchorEl] = useState(null)
  const { postageAlerts, handlePostageAlertsUpdate } = usePostageAlerts({})
  const {
    data: { client_meta },
  } = usePostageBalance()
  const clients = useMemo(() => {
    return Object.entries(client_meta || {}).map(([client_id, meta]) => ({
      ...meta,
      client_id,
    }))
  }, [client_meta])
  const { setBasicNotification, setError } = useNotification()

  const initialValues = useMemo(() => {
    const values = {
      clients: {},
    }

    clients.forEach((client) => {
      values.clients[client.client_id] = {
        name: client.client_name,
        id: client.client_id,
      }

      NOTIFICATION_OPTIONS.forEach((opt) => {
        values.clients[client.client_id][opt] = parseInt(
          postageAlerts?.[client.client_id]?.[opt] || 0
        )
      })
    })

    return values
  }, [clients, postageAlerts])

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await handlePostageAlertsUpdate(values)
        setBasicNotification('Notifications preferences successfully updated!')
      } catch (err) {
        setError(
          'Unable to update notification preferences. Try again later.',
          err
        )
      }
    },
    [handlePostageAlertsUpdate, setBasicNotification, setError]
  )

  return (
    <Box sx={{ my: 'auto' }}>
      <IconButton
        sx={{ my: 'auto' }}
        title="Edit Notifications Preferences"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <BellIcon color="primary" />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Card
          sx={{
            padding: 4,
            border: '1px solid',
            borderColor: 'lightgray.main',
            minHeight: '90vh',
          }}
        >
          <Typography variant="h1">Notification Preferences</Typography>
          {!Boolean(clients?.length) && (
            <Box p={4}>
              <Typography textAlign="center">No clients available.</Typography>
            </Box>
          )}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({ values, submitForm, resetForm }) => (
              <Stack sx={{ position: 'relative' }}>
                {clients.map((client) => (
                  <PostageOption client={client} />
                ))}
                {!deepEqual(values, initialValues) && (
                  <>
                    <Fab
                      title="Cancel Changes"
                      color="secondary"
                      sx={{ position: 'fixed', right: 96, bottom: 24 }}
                      onClick={() => resetForm()}
                    >
                      <XIcon />
                    </Fab>
                    <Fab
                      title="Save Changes"
                      color="primary"
                      sx={{ position: 'fixed', right: 28, bottom: 24 }}
                      onClick={() => submitForm()}
                    >
                      <SaveIcon />
                    </Fab>
                  </>
                )}
              </Stack>
            )}
          </Formik>
        </Card>
      </Popover>
    </Box>
  )
}

export default EditNotifications
