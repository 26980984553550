import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { Link, usePrompt } from 'react-router-dom'
import { useFormikContext } from 'formik'
import differenceInDays from 'date-fns/differenceInDays'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Tooltip from '@mui/material/Tooltip'
import ToggleButton from '@mui/material/ToggleButton'
import Typography from '@mui/material/Typography'

import ChatBubbleIcon from '@mui/icons-material/ChatBubble'

import useDocumentInboundItem from 'hooks/digital_mail/useDocumentInboundItem'
import useDocumentInboundNotes from 'hooks/digital_mail/useDocumentInboundNotes'
import useModal from 'hooks/context/useModal'
import useNotification from 'hooks/context/useNotification'
import { useDocument } from 'hooks/useDocuments'

import Modal from 'components/common/Modal'
import DocumentPreviewContent from 'components/common/BulkPreview/components/PreviewContent/DigitalMailboxDocumentPreview'
import NotesDrawer from 'components/notes/NotesDrawer'
import DigitalMailDrawer from 'components/notes/NotesDrawer/DigitalMail'
import RedactModalV2 from './RedactModalV2'
import MailOriginalModal from 'components/digital_mailbox/MailOriginalModal'
import DocumentHistoryModal from 'components/digital_mailbox/DocumentHistoryModal'

import capitalize from 'helpers/node/capitalize'
import getPdfPageCount from 'helpers/browser/getPdfPageCount'

import { useDocumentPdf } from 'hooks/useDocuments'
import useConfiguration from 'hooks/useConfiguration'

import { PRIVILEGES } from 'utils/constants'
import SplitModal from './SplitModal'
import isEmptyObject from 'helpers/node/isEmptyObject'

function HeaderLayoutButtons({
  pdfPageCount,
  setRedactOpen,
  setSplitOpen,
  document,
}) {
  const { downloadDocument } = useDocument()
  const { hasPrivilege } = useConfiguration()
  const { setBasicNotification, setError } = useNotification()

  const showDownload = hasPrivilege(PRIVILEGES.DOWNLOAD_ITEM)
  const showSplit = hasPrivilege(PRIVILEGES.SPLIT_DOCUMENT)
  const showRedact = hasPrivilege(PRIVILEGES.REDACT_DOCUMENT)
  const handleDownload = async (document) => {
    try {
      await downloadDocument(document)
      setBasicNotification(
        `Document ID ${document.id} has been automatically downloaded to your computer.`
      )
    } catch (err) {
      setError(err.response?.data?.display_message || err.message)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        height: '100%',
        mr: 4,
        '& > .MuiButton-root': { my: 'auto', mr: 4 },
      }}
    >
      {showSplit && (
        <Button
          size="action-header"
          color="primary"
          variant="outlined"
          onClick={() => setSplitOpen(true)}
          disabled={!pdfPageCount}
          sx={{ mr: 4 }}
        >
          Split
        </Button>
      )}
      {showRedact && (
        <Button
          size="action-header"
          color="secondary"
          variant="contained"
          onClick={() => setRedactOpen(true)}
          disabled={!pdfPageCount}
          sx={{ mr: 4 }}
        >
          Redact
        </Button>
      )}
      {showDownload && (
        <Button
          size="action-header"
          color="primary"
          variant="contained"
          onClick={() => handleDownload(document?._embedded?.document)}
          disabled={!pdfPageCount}
          sx={{ mr: 4 }}
        >
          Download
        </Button>
      )}
    </Box>
  )
}

function HeaderActionButtons({
  updateInboundItem,
  setMailOriginalOpen,
  open,
  setOpen,
  duplicateItem,
  inboundId,
  document,
}) {
  const { setComponent, setOpen: setModalOpen, setModalProps } = useModal()
  const { setError, setBasicNotification } = useNotification()

  const handleLitigationClick = useCallback(() => {
    setComponent(Modal)
    setModalProps({
      title: 'Copy to Litigation',
      size: 'sm',
      children:
        'This document will be duplicated alongside all related metadata, and the copy will be moved to Litigation.',
      footerButtonProps: [
        {
          children: 'Confirm',
          variant: 'contained',
          color: 'primary',
          onClick: async () => {
            try {
              const {
                _embedded: { document_inbound_items },
              } = await duplicateItem(inboundId, { category: 'LITIGATION' })
              const newDoc = document_inbound_items[1]
              setBasicNotification(
                <Box>
                  <Typography>
                    Document has been successfuly duplicated! See Doc{' '}
                    <Link
                      to={`/correspondence-hub/digital-mailbox/${newDoc.document_id}`}
                    >
                      {newDoc.document_id}.
                    </Link>
                  </Typography>
                </Box>
              )
            } catch (err) {
              // handle case where there request was successful but there was a JS error
              if (err.response?.status !== 200) {
                setError('Unable to duplicate document', err)
              } else {
                setBasicNotification(
                  'Document has been successfuly duplicated!'
                )
              }
            } finally {
              setModalOpen(false)
            }
          },
        },
        {
          children: 'Cancel',
          variant: 'outlined',
          color: 'primary',
          onClick: () => setModalOpen(false),
        },
      ],
    })
    setModalOpen(true)
  }, [setComponent, setModalOpen, setModalProps])

  const { submitForm, status, errors } = useFormikContext()
  const { hasPrivilege } = useConfiguration()

  const showDuplicate =
    hasPrivilege(PRIVILEGES.ESCALATE_ITEM, null, document) &&
    `${document.category}`.toLowerCase().trim() === 'legal'

  const showMailOriginal = useMemo(() => {
    if (
      document?._embedded?.is_fax ||
      document?.source_document_id
    ) {
      // fax cannot be mail original, nor can documents with a parent
      return false;
    }
    // date-fns functions like to throw errors
    try {
      const dateDiff = Math.abs(
        differenceInDays(
          new Date(document._embedded.document.datetime_created),
          new Date() // today
        )
      )

      if (dateDiff > 90) {
        return false
      }

      return hasPrivilege(PRIVILEGES.MAIL_ORIGINAL, null, {
        'inbound-category': document?.category,
      })
    } catch (err) {
      return false
    }
  }, [hasPrivilege, document])

  const showArchive = hasPrivilege(PRIVILEGES.ARCHIVE_ITEM)

  const handleArchive = async () => {
    const notArchived = !document.is_archived

    try {
      await updateInboundItem({
        is_archived: notArchived,
        status: notArchived ? 'CLOSED' : undefined,
      })
    } catch (err) {
      if (err.response?.data?.display_message) {
        setError(err.response?.data?.display_message)
      } else {
        setError(err.response?.data?.message)
      }

      return
    }

    setModalOpen(false)

    const notificationContent = (
      <>
        Document ID {document._embedded?.document?.id} has been{' '}
        {notArchived ? 'archived' : 'unarchived'} successfully.
        {notArchived && (
          <>
            You can find it in the{' '}
            <Box
              component={Link}
              sx={{ textDecoration: 'underline' }}
              to="/correspondence-hub/digital-mailbox"
            >
              Archived
            </Box>{' '}
            tab.
          </>
        )}
      </>
    )

    setBasicNotification(notificationContent)
  }

  const showArchiveModal = () => {
    const action = document.is_archived ? 'unarchive' : 'archive'
    const capitalizedAction = capitalize(action)

    const archiveModalProps = {
      title: `${capitalizedAction} Document`,
      children: (
        <>
          <Typography color="darkgray.main" pb={1}>
            You are about to {action} Document {document.document_id}.
          </Typography>
          <Typography color="darkgray.main">
            Please confirm this action and click {capitalizedAction} below, or
            click Cancel to go back to the previous screen.
          </Typography>
        </>
      ),
      width: '414px',
      height: '368px',
      footerButtonProps: [
        {
          children: capitalizedAction,
          color: 'primary',
          variant: 'contained',
          onClick: () => handleArchive(),
        },
        {
          children: 'Cancel',
          color: 'primary',
          variant: 'outlined',
          onClick: () => setModalOpen(false),
        },
      ],
      hideButtonsBorder: true,
    }

    setModalProps(archiveModalProps)
    setModalOpen(true)
  }

  return (
    <Box
      sx={{
        height: '88px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          my: 'auto',
          display: 'flex',
          minWidth: '456px',
          justifyContent: 'space-between',
        }}
      >
        {showArchive && (
          <Button
            color="primary"
            variant="outlined"
            size="action-footer"
            onClick={() => showArchiveModal()}
          >
            {document.is_archived ? 'Unarchive' : 'Archive'}
          </Button>
        )}
        {showMailOriginal && (
          <Tooltip
            title={
              Boolean(document?._embedded?.has_pull_request)
                ? 'Mail Original request already in progress'
                : 'Mail Original Document'
            }
          >
            <Box>
              <Button
                color="primary"
                variant="outlined"
                size="action-footer"
                onClick={() => setMailOriginalOpen(true)}
                disabled={Boolean(document?._embedded?.has_pull_request)}
              >
                Mail Original
              </Button>
            </Box>
          </Tooltip>
        )}
        {showDuplicate && (
          <Button
            color="primary"
            variant="outlined"
            size="action-footer"
            onClick={handleLitigationClick}
            sx={{ mr: 4 }}
          >
            Litigation
          </Button>
        )}
        <Button
          color="secondary"
          variant="contained"
          size="action-footer"
          sx={{ visibility: !status.edited && 'hidden' }}
          onClick={() => submitForm()}
          disabled={!isEmptyObject(errors)}
        >
          Save Changes
        </Button>
      </Box>
      <ToggleButton
        sx={{ height: '40px', width: '64px', my: 'auto' }}
        selected={!open}
        value={open}
        onClick={() => setOpen(!open)}
      >
        <ChatBubbleIcon />
      </ToggleButton>
    </Box>
  )
}

function PreviewContent({
  displayDocument,
  columns,
  getDataDisplay,
  setDocumentHistoryOpen,
  pdf,
}) {
  return (
    <DocumentPreviewContent
      activeDocument={displayDocument}
      columns={columns}
      getDataDisplay={getDataDisplay}
      onClick={() => setDocumentHistoryOpen(true)}
      pdf={pdf}
    />
  )
}

function DocumentPreview({
  columns,
  getDataDisplay,
  displayDocument,
  inboundId,
}) {
  const { dirty, status } = useFormikContext()
  const {
    documentInboundItem: document,
    loading,
    updateInboundItem,
    mailOriginalItem,
    redactItemBySection,
    splitItem,
    duplicateItem,
  } = useDocumentInboundItem(inboundId)

  usePrompt(
    'You have unsaved changes. Are you sure you want to leave this page?',
    dirty && status.edited
  )

  // states
  const [pdf, setPdf] = useState(null)
  const [pdfPageCount, setPdfPageCount] = useState(0)
  const [open, setOpen] = useState(false)
  const [redactOpen, setRedactOpen] = useState(false)
  const [splitOpen, setSplitOpen] = useState(false)
  const [mailOriginalOpen, setMailOriginalOpen] = useState(false)
  const [documentHistoryOpen, setDocumentHistoryOpen] = useState(false)
  const {
    notes,
    loading: notesLoading,
    makeNote,
  } = useDocumentInboundNotes(inboundId)
  const { getPdfByDocumentId } = useDocumentPdf({})

  const handleSubmitNotes = async (
    values,
    { setSubmitting, setFieldValue }
  ) => {
    await makeNote(values.note)
    setFieldValue('note', '')
    setSubmitting(false)
  }

  const fetchPdf = async () => {
    setPdf(await getPdfByDocumentId(document?._embedded?.document.id))
  }

  useEffect(() => {
    if (!pdf && document) fetchPdf()
  }, [document])

  useEffect(() => {
    if (pdf) {
      getPdfPageCount(pdf).then(setPdfPageCount)
    }
  }, [pdf])

  return (
    <>
      <SplitModal
        open={splitOpen}
        onClose={() => setSplitOpen(false)}
        document={document}
        splitItem={splitItem}
        pageCount={pdfPageCount}
        pdf={pdf}
        fetchPdf={fetchPdf}
      />
      <RedactModalV2
        open={redactOpen}
        onClose={() => setRedactOpen(false)}
        document={document}
        redactItem={redactItemBySection}
        pageCount={pdfPageCount}
        pdf={pdf}
        fetchPdf={fetchPdf}
      />
      <MailOriginalModal
        open={mailOriginalOpen}
        onClose={() => setMailOriginalOpen(false)}
        document={document}
        mailOriginalItem={mailOriginalItem}
      />
      <DocumentHistoryModal
        open={documentHistoryOpen}
        onClose={() => setDocumentHistoryOpen(false)}
        document={document}
        inboundId={inboundId}
      />
      <Box
        sx={{
          height: '72px',
          background: 'white',
          display: 'flex',
          borderBottom: '1px solid',
          borderColor: 'darkblue.light',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h1" sx={{ ml: 8, my: '28px' }}>
          {document && `Document ID ${document.document_id ?? ''}`}
        </Typography>
        <HeaderLayoutButtons
          pdfPageCount={pdfPageCount}
          setRedactOpen={setRedactOpen}
          setSplitOpen={setSplitOpen}
          document={document}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ px: 8, width: '100%' }}>
          <HeaderActionButtons
            updateInboundItem={updateInboundItem}
            setMailOriginalOpen={setMailOriginalOpen}
            setOpen={setOpen}
            open={open}
            inboundId={inboundId}
            duplicateItem={duplicateItem}
            document={document}
          />
          <Card sx={{ boxShadow: 2, mb: 3 }}>
            {document && !loading && (
              <PreviewContent
                displayDocument={displayDocument}
                columns={columns}
                getDataDisplay={getDataDisplay}
                setDocumentHistoryOpen={setDocumentHistoryOpen}
                pdf={pdf}
              />
            )}
          </Card>
        </Box>
        <Box sx={{ position: 'relative' }}>
          <NotesDrawer
            notes={notes}
            loading={notesLoading}
            open={open}
            setOpen={setOpen}
            label="Document ID"
            id={document?.document_id}
            handleSubmit={handleSubmitNotes}
            NotesDrawerComponent={DigitalMailDrawer}
          />
        </Box>
      </Box>
    </>
  )
}

export default DocumentPreview
