import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import deepEqual from 'deep-equal'

import Box from '@mui/material/Box'

import { ReactComponent as DocumentIcon } from 'svgs/statuses/document.svg'
import { ReactComponent as BellIcon } from 'svgs/statuses/bell.svg'

import useDataList from 'hooks/useDataList'
import useDataListContext from 'hooks/context/useDataListContext'
import useDocuments, { useAllDocuments, useDocument } from 'hooks/useDocuments'
import useNotification from 'hooks/context/useNotification'

import ResultsListHeader from './Header'
import ResultsListSidebar from './Sidebar'
import DataList from 'components/common/DataList'
import BulkPreview from 'components/common/BulkPreview'
import CorrespondencePreviewContent from 'components/common/BulkPreview/components/PreviewContent/Correspondence'
import ExportToCSV from 'components/common/ExportToCSV'

import useCorrespondence from 'hooks/correspondence/useCorrespondence'

function ResultsList() {
  // routing
  const navigate = useNavigate()
  const location = useLocation()

  // notification
  const { setError } = useNotification()

  const { getUpdatedFieldValues } = useCorrespondence()

  // datalist
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sortList, setSortList] = useState([])
  const [searchFields, setSearchFields] = useState(location.state?.fields ?? [])
  const [searchDisplays] = useState(location.state?.displays ?? [])
  const [locationState] = useState(location.state)
  const [filterList, setFilterList] = useState(
    getUpdatedFieldValues(locationState ?? {})
  )

  const filterCount = useMemo(() => {
    try {
      let dateApplied = false

      return (
        Object.entries(filterList).reduce((acc, [k, v]) => {
          if (!v) return acc

          if (k.includes('date_doc')) {
            const returnValue = !dateApplied ? 1 : 0
            dateApplied = true
            return acc + returnValue
          } else if (k.includes('search')) {
            return acc + v.split(/\|/gm).length / 2
          } else {
            return acc + 1
          }
        }, 0) - 1
      )
    } catch (err) {
      return 0
    }
  }, [filterList])

  const {
    page,
    pageSize,
    setTotal,
    setTotalPages,
    setActiveIndex,
    layoutType,
    setLayoutType,
    setPageSize,
    clearChecked,
    initialized,
    setInitialized,
    requestParams,
    setParams,
  } = useDataListContext()

  const documentsHookParams = useMemo(
    () =>
      searchFields && {
        'paging[page]': page,
        'paging[page_size]': pageSize,
        embed: 'notification|notes|metadata|linked_notification_documents',
        ...filterList,
        ...sortList,
      },
    [page, pageSize, filterList, sortList, searchFields]
  )

  const { downloadDocument } = useDocument()

  const {
    documents,
    total,
    loading,
    totalPages,
    pageLimit,
    pageSize: docPageSize,
    deactivateDocuments,
  } = useDocuments(documentsHookParams)

  const getStatusDisplay = useCallback((rowData) => {
    const { notification } = rowData._embedded ?? {}

    let success = true
    let display = 'Document'

    if (rowData?.record_type === 'notification') {
      const { status, type } = notification
      success = status === 'Delivered'
      display = `${type} Delivery ${success ? 'succeeded' : 'failed'}`
    }

    const types = {
      document: {
        icon: DocumentIcon,
        name: 'Document',
        display,
      },
      notification: {
        icon: BellIcon,
        name: 'Bell',
        display,
      },
    }

    const recordType = types[rowData.record_type]

    return {
      ...recordType,
      success,
      display,
      color: success ? 'success.main' : 'warning.main',
    }
  }, [])

  const prepareRowData = useCallback(
    (row) => ({
      type: getStatusDisplay(row),
      ...row,
    }),
    [getStatusDisplay]
  )

  const adjustedDocuments = useMemo(() => {
    const addEmbeds = (document) => {
      const result = { ...document }
      const metadata = result._embedded.metadata

      if (document?._embedded?.metadata) {
        Object.keys(metadata).forEach((key) => {
          result[`_.metadata.${key}`] = metadata[key]
        })
      }

      return result
    }

    return documents.map(addEmbeds)
  }, [documents])

  const getColumnDisplay = useCallback(
    (col) => {
      const editDisplay = (display) =>
        display
          .replace(/_/gm, ' ')
          .replace(/.metadata./gm, '')
          .replace(/.notification./gm, 'Notified ')
          .toUpperCase()

      let colDisplay = col
      const foundIndex = searchFields.findIndex((field) => field === col)

      if (foundIndex > -1) {
        colDisplay = searchDisplays[foundIndex]
      }

      return editDisplay(colDisplay)
    },
    [searchFields, searchDisplays]
  )

  const { dataList, columns, dataListProps } = useDataList({
    baseRowData: adjustedDocuments ?? [],
    presetName: 'correspondence',
    getStatusDisplay,
    setSortList,
    prepareRowData,
    getColumnDisplay,
  })

  const overLimit = useMemo(() => {
    return (total || 0) > (pageLimit || 0)
  }, [total, pageLimit])

  const { getAllDocuments, getCsvExport } = useAllDocuments(
    {
      ...documentsHookParams,
      fields: searchFields.join('|'),
    },
    !loading && total,
    dataListProps.getDataDisplay,
    searchFields,
    getColumnDisplay,
    pageLimit
  )

  const handleSingleDownload = async ({ document }) => {
    try {
      await downloadDocument(document)
    } catch (err) {
      setError(err.message)
    }
  }

  const [actionButtonProps] = useState([
    {
      children: 'View',
      color: 'primary',
      variant: 'outlined',
      size: 'action-header',
      sx: {
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: 2,
        },
      },
      onClick: ({ index }) => {
        setActiveIndex(index)
        setLayoutType('preview')
      },
    },
    {
      children: 'Download',
      color: 'primary',
      variant: 'outlined',
      size: 'action-header',
      sx: {
        bgcolor: 'background.paper',
        '&:hover': {
          bgcolor: 'background.paper',
          boxShadow: 2,
        },
      },
      onClick: (props) => handleSingleDownload({ document: props.rowData }),
    },
  ])

  useEffect(() => {
    setInitialized(!loading)
  }, [loading])

  // handle case where user changes their search
  useEffect(() => {
    if (location.state?.fields) {
      setSearchFields(location.state.fields)
    }

    if (location.state?.values) {
      const updatedFieldValues = getUpdatedFieldValues(location.state)
      setFilterList(updatedFieldValues)
    }

    // redirect to saved search page on refresh
    if (location.state)
      navigate('/correspondence-hub/correspondence/results', { replace: true })
  }, [location.state])

  // redirect to saved search page if accessing this page directly
  useEffect(() => {
    if (!locationState)
      navigate('/correspondence-hub/correspondence', { replace: true })
  }, [locationState])

  useEffect(() => {
    if (total !== undefined) {
      setTotal(total)
      setTotalPages(totalPages) // assume total pages updates alongside total
      setPageSize(docPageSize, false)
    }
  }, [total, totalPages])

  // resets checked whenever documents changes
  useEffect(() => {
    clearChecked()
  }, [page, total])

  // store search params in context (for swr)
  useEffect(() => {
    if (!deepEqual(documentsHookParams, requestParams))
      setParams(documentsHookParams)
  }, [documentsHookParams])

  const dataListComponent = useMemo(() => {
    const isPreview = layoutType === 'preview'
    const columnCount = isPreview ? 2 : 7

    return (
      <DataList
        {...dataListProps}
        dataList={dataList}
        columns={columns}
        columnCount={columnCount}
        loading={loading}
        validating={initialized}
        actionButtonProps={actionButtonProps}
        layoutType={layoutType}
        visibleColumns={['type', ...searchFields]}
        itemProps={{
          expandedListProps: {
            getColumnDisplay,
          },
        }}
      />
    )
  }, [
    dataListProps,
    dataList,
    layoutType,
    searchFields,
    actionButtonProps,
    columns,
    loading,
    initialized,
  ])

  const dataListDisplay = useMemo(() => {
    return (
      <BulkPreview
        dataListComponent={dataListComponent}
        dataList={dataList}
        dataListProps={dataListProps}
        columns={columns}
        loading={loading}
        PreviewComponent={CorrespondencePreviewContent}
        sidebarOpen={sidebarOpen}
      />
    )
  }, [
    dataList,
    dataListProps,
    loading,
    columns,
    dataListComponent,
    sidebarOpen,
  ])

  return (
    <>
      <Box
        sx={{
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        <Box sx={{ px: 8, pt: 6, width: '100%' }}>
          <ResultsListHeader
            documents={documents}
            searchParams={documentsHookParams}
            deactivateDocuments={deactivateDocuments}
            open={sidebarOpen}
            setOpen={setSidebarOpen}
            loading={loading}
            filterCount={filterCount}
            getAllDocuments={getAllDocuments}
          />
          {dataListDisplay}
        </Box>
        <ExportToCSV
          getCsvExport={getCsvExport}
          onClick={overLimit ? getCsvExport : undefined}
          filename={`Correspondence.${Date.now()}.csv`}
          sx={{ ml: 8 }}
          hide={loading || dataList.length <= 0}
        />
      </Box>
      <ResultsListSidebar
        open={sidebarOpen}
        setOpen={setSidebarOpen}
        locationState={locationState}
      />
    </>
  )
}

export default ResultsList
