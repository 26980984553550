import useSWR from 'swr'
import { useCallback } from 'react'
import { cloneDeep } from 'lodash'

import { etagFetcher } from 'utils/fetcher'
import axios from 'utils/axios'

import useAuth from 'hooks/useAuth'

const usePostageAlerts = ({ userId = 0 }) => {
  const { user } = useAuth()

  const apiUserId = userId || user?.userId

  const { data, isValidating, mutate } = useSWR(
    apiUserId ? `/users/${apiUserId}/alerts/postage` : null,
    etagFetcher,
    {
      revalidateOnFocus: false,
    }
  )

  const handlePostageAlertsUpdate = useCallback(
    async (values) => {
      const updatedValues = cloneDeep(values.clients)

      Object.keys(updatedValues).forEach((key) => {
        delete updatedValues[key].name
        delete updatedValues[key].id
      })

      const {
        headers: { etag },
      } = await axios.get(`/users/${apiUserId}/alerts/postage`)

      await axios.put(
        `/users/${apiUserId}/alerts/postage`,
        { clients: updatedValues },
        {
          headers: {
            'If-Match': etag,
          },
        }
      )
      mutate()
    },
    [apiUserId, data]
  )

  return {
    postageAlerts: data?._embedded?.clients ?? [],
    loading: (!data?._embedded?.clients && !data?.error) || isValidating,
    error: data?.error,
    mutate,
    handlePostageAlertsUpdate,
  }
}

export default usePostageAlerts
