import format from 'date-fns/format'

import useConfiguration from 'hooks/useConfiguration'

import getUTCDateRange from 'helpers/datetime/getUTCDateRange'

const useCorrespondence = () => {
  const { fieldDefinitions } = useConfiguration()

  const getUpdatedFieldValues = ({
    values,
    defaultSearch = ['is_active|true'],
  }) => {
    if (values) {
      const fieldValues = {
        search: defaultSearch,
      }
      const validFields = Object.keys(values).filter((key) => values[key])

      const addField = (field) => {
        const exactMatch = [
          'alternate_id',
          '_.metadata.Description',
          'record_type',
          'tracking_number',
        ]

        if (fieldDefinitions[field]) {
          switch (fieldDefinitions[field].data_type) {
            case 'date': {
              const [start, end] = values[field]

              if (!start && !end) break

              fieldValues[`${field}[start]`] = format(start, 'yyyy-MM-dd')
              fieldValues[`${field}[end]`] = format(end ?? start, 'yyyy-MM-dd')

              break
            }
            case 'datetime': {
              const [start, end] = values[field]

              if (!start && !end) break

              const [utcStart, utcEnd] = getUTCDateRange(start, end ?? start)

              fieldValues[`${field}[start]`] = format(
                utcStart,
                `yyyy-MM-dd'T'hh:mm:ss+0000`
              )
              fieldValues[`${field}[end]`] = format(
                utcEnd,
                `yyyy-MM-dd'T'hh:mm:ss+0000`
              )

              break
            }
            default: {
              const fieldValue = `${values[field]}${
                exactMatch.includes(field) || field.includes('metadata')
                  ? ''
                  : '*'
              }`
              fieldValues.search.push([field, fieldValue].join('|'))
              break
            }
          }
        } else {
          const fieldValue = `${values[field]}${
            exactMatch.includes(field) || field.includes('metadata') ? '' : '*'
          }`
          fieldValues.search.push([field, fieldValue].join('|'))
        }
      }

      validFields.forEach(addField)

      fieldValues.search = fieldValues.search.join('|')

      return fieldValues
    } else {
      return { search: '' }
    }
  }

  return {
    getUpdatedFieldValues,
  }
}

export default useCorrespondence
